import React, { useEffect, useState } from "react";
import {
  Card,
  Grid,
  CardHeader,
  Typography,
  IconButton,
  Table,
  TableContainer,
  TableCell,
  TableRow,
  FormGroup,
  FormControlLabel,
  Switch,
  Stack,
  Select,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { prices } from "../data/data";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

export const Prices = ({ party }: { party: boolean }) => {
  const { t, i18n } = useTranslation();
  const [isGroupDiscount, setIsGroupDiscount] = useState(false);
  const [groupSize, setGroupSize] = useState(10);
  const location = useLocation();
  const currentPath = location.pathname;

  useEffect(() => {
    setIsGroupDiscount(party);
  }, [party]);

  const calculatePrice = (price: number, applyDiscount: boolean) => {
    if (
      applyDiscount &&
      isGroupDiscount &&
      price !== prices.donkey &&
      price !== prices.golf &&
      price !== prices.tour
    ) {
      const discount = (price * groupSize) / 100;
      const discountedPrice = price - discount;
      return discountedPrice.toFixed(2) + " €";
    } else if (
      applyDiscount &&
      isGroupDiscount &&
      (price === prices.donkey ||
        price === prices.golf ||
        price === prices.tour)
    ) {
      const discount = price * 0.25;
      const discountedPrice = price - discount;
      return discountedPrice.toFixed(2) + " €";
    }
    return price.toFixed(2) + " €";
  };

  return (
    <Grid padding={1} id='prices'>
      <Grid className='boxYellow'>
        <Card className='boxBorder'>
          <CardHeader
            action={<IconButton aria-label='settings'></IconButton>}
            title={
              <Typography variant='h5'>
                {t("prices.title") + " " + prices.year}
              </Typography>
            }
            subheader={
              isGroupDiscount ? (
                <Typography color={"red"} variant='body2'>
                  {" "}
                  {t("prices.requestDisc")}
                </Typography>
              ) : null
            }
            className='boxYellow'
          />
          <TableContainer className='boxYellow'>
            <Table>
              <tbody>
                {party && (
                  <>
                    <TableRow>
                      <TableCell>
                        <Typography fontWeight='bold'>
                          {t("prices.booking")}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography fontWeight='bold'>
                          {calculatePrice(prices.booking, false)}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </>
                )}
                <TableRow>
                  <TableCell>{t("prices.adult")}</TableCell>
                  <TableCell style={{ whiteSpace: "nowrap" }}>
                    {calculatePrice(prices.adult, true)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("prices.kids")}</TableCell>
                  <TableCell>{calculatePrice(prices.kids, true)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("prices.toddler")}</TableCell>
                  <TableCell>{calculatePrice(prices.toddler, true)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("prices.retired")}</TableCell>
                  <TableCell>{calculatePrice(prices.retired, false)}</TableCell>
                </TableRow>
                {!party && (
                  <>
                    <TableRow>
                      <TableCell>{t("prices.teacher")}</TableCell>
                      <TableCell>
                        {calculatePrice(prices.teacher, false)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{t("prices.disabledA")}</TableCell>
                      <TableCell>
                        {calculatePrice(prices.disabledA, false)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{t("prices.disabledK")}</TableCell>
                      <TableCell>
                        {calculatePrice(prices.disabledK, false)}
                      </TableCell>
                    </TableRow>
                    {!currentPath.includes("/simpleHome")
                      ? isGroupDiscount || (
                          <TableRow>
                            <TableCell>
                              {t("menu.workshop")}
                              <br />
                              <Typography variant='caption' color={"red"}>
                                <Link to={"/" + i18n.language + "/workshop"}>
                                  {t("prices.workshop")}
                                </Link>
                              </Typography>
                            </TableCell>
                            <TableCell>
                              {calculatePrice(prices.workshop, false)}
                            </TableCell>
                          </TableRow>
                        )
                      : null}
                  </>
                )}
                <TableCell sx={{ padding: "0" }} colSpan={2}>
                  <Accordion
                    disableGutters={true}
                    square={true}
                    style={{ backgroundColor: "transparent" }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls='extra-activities-content'
                      id='extra-activities-header'>
                      {t("prices.extraActivities")}
                    </AccordionSummary>
                    <AccordionDetails>
                      <Table>
                        <TableRow>
                          <TableCell>{t("prices.guide")}</TableCell>
                          <TableCell>
                            {calculatePrice(prices.guide, false)}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>{t("prices.chicken")}</TableCell>
                          <TableCell>
                            {calculatePrice(prices.chicken, false)}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>{t("prices.donkey")}</TableCell>
                          <TableCell>
                            {calculatePrice(prices.donkey, true)}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>{t("prices.golf")}</TableCell>
                          <TableCell>
                            {calculatePrice(prices.golf, true)}
                          </TableCell>
                        </TableRow>
                      </Table>
                    </AccordionDetails>
                  </Accordion>
                </TableCell>
                {isGroupDiscount && (
                  <>
                    <TableRow>
                      <TableCell>
                        {t("prices.tour")}
                        <br />
                        <Typography variant='body2' color={"red"}>
                          {t("prices.tourBook")}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {calculatePrice(prices.tour, false)}
                      </TableCell>
                    </TableRow>
                  </>
                )}
              </tbody>
            </Table>
          </TableContainer>
          <Stack spacing={2} direction='row' className='boxYellow' padding={2}>
            {!party && (
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={isGroupDiscount}
                      onChange={(event) =>
                        setIsGroupDiscount(event.target.checked)
                      }
                    />
                  }
                  label={t("prices.group")}
                />
              </FormGroup>
            )}

            {isGroupDiscount && (
              <FormGroup>
                <FormControlLabel
                  control={
                    <Select
                      size='small'
                      variant='outlined'
                      value={groupSize}
                      onChange={(event) =>
                        setGroupSize(parseInt(event.target.value as string, 10))
                      }>
                      <MenuItem value={10}>+10</MenuItem>
                      <MenuItem value={15}>+20</MenuItem>
                      <MenuItem value={20}>+30</MenuItem>
                    </Select>
                  }
                  label={t("prices.groupSize") + ":"}
                  labelPlacement='start'
                />
              </FormGroup>
            )}
          </Stack>
          <Stack padding={0} direction='row' className='boxYellow'>
            <Typography variant='caption' color={"red"}>
              {t("prices.paymentOptions")}
            </Typography>
          </Stack>
        </Card>
      </Grid>
    </Grid>
  );
};
